<template>
  <div>
    <vs-card>
      <apexchart type="line" height="310" :options="config.chartOptions" :series="config.series" :key="renderKey"></apexchart>
    </vs-card>
  </div>

</template>

<script>
import VueApexCharts from "vue-apexcharts";

import moment from 'moment-timezone'
import Vue from 'vue'

export default {
  name: 'EmployeeGraph1',
  props: ['series', 'type'],
  components: {
    apexchart: VueApexCharts,
  },
  watch: {
    series(val){

      Vue.set(this.config.series[0], 'data', val.arrDauY)
      Vue.set(this.config.chartOptions.xaxis, 'categories', val.arrDauX)
      this.renderKey = Math.random()

    }
  },
  created(){
    let val = this.series


    this.config.series[0].data = val.arrDauY
    this.config.chartOptions.xaxis.categories = val.arrDauX

  },
  data() {
    return {
      renderKey: 0,
      config: {
        series: [{
          name: 'DAU',
          data: []
        }],
        chartOptions: {
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              colorStops: [
                {
                  offset: 0,
                  color: "#F14668",
                  opacity: 1
                },
                {
                  offset: 100,
                  color: "#F14668",
                  opacity: 1
                },
              ]
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 0,
              columnWidth: '70%',
              barHeight: '70%',
              distributed: false,
              rangeBarOverlap: true,
              rangeBarGroupRows: false,
              colors: {
                ranges: [{
                  from: 255,
                  to: 255,
                  color: undefined
                }],
                backgroundBarColors: ['#eee'],
                backgroundBarOpacity: 1,
                backgroundBarRadius: 0,
              },
              dataLabels: {
                position: 'top',
                maxItems: 100,
                hideOverflowingLabels: true,
                orientation: 'horizontal'
              }
            }
          },

          stroke: {
            curve: 'straight',
            // OR provide an array
            // curve: ['smooth', 'straight', 'stepline']
          },
          markers: {
            size: 5,
            fillColor: '#16C79A',
            strokeColor: '#16C79A',
            colors: '#fff',
            strokeWidth: 2,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            shape: "square",
            radius: 3,
            offsetX: 0,
            offsetY: 0,
            onClick: undefined,
            onDblClick: undefined,
            showNullDataPoints: true,
            hover: {
              size: undefined,
              sizeOffset: 3
            }
          },

          chart: {
            height: 350,
            type: 'datetime',
            zoom: {
              enabled: false
            }
          },
          dataLabels: {
            enabled: false,
          },

          title: {
            text: '',
            align: 'left'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          xaxis: {
            categories: [],
            // labels: {
            //   formatter: function (value) {
            //     return value;
            //   }
            // }

            // labels: {
            //   formatter: function(val) {
            //     return "Q" + dayjs(val).quarter()
            //   }
            // },
          }
        },
      }

    }
  },
  methods:{

  }
}
</script>
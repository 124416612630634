<template>
  <vs-card>
    <div class="flex justify-around">
      <vue-word-cloud
          style=" height: 200px; width: 70%; "
          :words="data"
          :color="getColor"
          font-family="KoHo"
      />
    </div>

  </vs-card>
</template>

<script>
import VueWordCloud from 'vuewordcloud';

export default {
  name: 'ContentGraph4',
  props: ['data'],
  components: {
    [VueWordCloud.name]: VueWordCloud,
  },
  methods:{
    getPct(val, arr){
      let total = 0
      for(let i=0; i<arr.length; i++){
        if(parseFloat(arr[i][1])){
          total += arr[i][1]
        }
      }

      let pct = 0
      if(val == 0 || total == 0){
        pct= 0
      }else{
        pct= parseInt(val/total*100)
      }

      console.log('--', pct)
      return pct
    },
    getColor(a,b,c){
      console.log(c)

      let itsval = a[1]
      let itspct = this.getPct(itsval, c)

      if(itspct>=100){
        return '#845EC2'
      }
      if(itspct>=90){
        return '#00C9A7'
      }
      if(itspct>75){
        return '#FF6F91'
      }
      if(itspct>50){
        return '#FFC75F'
      }
      if(itspct>25){
        return '#00AFFF'
      }
      if(itspct>10){
        return '#845EC2'
      }

      return '#C34A36'

      //([, weight]) => weight > 10 ? 'Green' : weight > 10 ? 'RoyalBlue' : 'Indigo'
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=KoHo&display=swap');
</style>
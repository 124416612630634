<template>
  <div>

    <div>

      <div class="w-full bg-blue-50 p-0">

        <div class="flex justify-start">


          <div class="flex flex-col bg-white rounded-lg  w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3 regmember">
            <vx-tooltip color="primary" text="Total Events" position="top">
            <statistics-card-line icon="ActivityIcon" :statistic="data.total_events" statisticTitle="Total Events" type="area"></statistics-card-line>
            </vx-tooltip>
          </div>


          <div class="flex flex-col bg-white rounded-lg  w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3 events">
            <vx-tooltip color="primary" text="How much of total employees participated in Events" position="top">
            <statistics-card-line icon="UsersIcon" :statistic="data.event_engagement_percent" statisticTitle="Event Participation Rate (%)" type="area"></statistics-card-line>
            </vx-tooltip>
          </div>

          <div class="flex flex-col bg-white rounded-lg  w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3 cafe">
            <vx-tooltip color="primary" text="Overall Approval rating of Events" position="top">
              <statistics-card-line icon="ThumbsUpIcon" :statistic="data.event_rating_percent" statisticTitle="Event Ratings (%)" type="area"></statistics-card-line>
            </vx-tooltip>
          </div>
     
          <div class="flex flex-col bg-white rounded-lg  w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3 Totlamedia">
            <vx-tooltip color="primary" text="Average Event length" position="top">
              <statistics-card-line icon="WatchIcon" :statistic="data.event_average_length" statisticTitle="Average Event Length (Hrs)" type="area"></statistics-card-line>
            </vx-tooltip>
          </div>


        </div>

      </div>

    </div>


  </div>
</template>

<script>
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";

export default {
  name: 'EventCards',
  props: [
      'data'
  ],
  data(){
    return {

    }
  },
  components: {
    StatisticsCardLine,
  },
}
</script>

<style>


.regmember{
box-shadow: #fba73d 0px 1px 2px 0px, #fba73d 0px 1px 3px 1px, #fba73d 0px -1px 2px 0px, #fba73d 0px -1px 3px 1px;
}
.regmember.rounded-lg span.feather-icon{
  background:#fba73d !important
}
.regmember.rounded-lg span svg,.Activeuser.rounded-lg span svg,.Totalvideo.rounded-lg span svg,.cafe.rounded-lg span svg,.events.rounded-lg span svg,.Totlamedia.rounded-lg span svg{
  color:#fff
}

.Activeuser{
box-shadow:#4fb6ea 0px 1px 2px 0px, #4fb6ea 0px 1px 3px 1px, #4fb6ea 0px -1px 2px 0px, #4fb6ea 0px -1px 3px 1px;
}
.Activeuser.rounded-lg span.feather-icon{
  background:#4fb6ea !important;
}


.Totalvideo{
box-shadow:#685aba 0px 1px 2px 0px, #685aba 0px 1px 3px 1px, #685aba 0px -1px 2px 0px, #685aba 0px -1px 3px 1px;
}
.Totalvideo.rounded-lg span.feather-icon{
  background:#685aba !important;
}

.cafe{
box-shadow:#56eb9f 0px 1px 2px 0px,#56eb9f 0px 1px 3px 1px, #56eb9f 0px -1px 2px 0px, #56eb9f 0px -1px 3px 1px;
}
.cafe.rounded-lg span.feather-icon{
  background:#56eb9f !important;
}


.events{
box-shadow:#f2513d 0px 1px 2px 0px,#f2513d 0px 1px 3px 1px,#f2513d 0px -1px 2px 0px, #f2513d 0px -1px 3px 1px;
}
.events.rounded-lg span.feather-icon{
  background:#f2513d !important;
}


.Totlamedia{
box-shadow:#d15494 0px 1px 2px 0px,#d15494 0px 1px 3px 1px,#d15494 0px -1px 2px 0px, #d15494 0px -1px 3px 1px;
}
.Totlamedia.rounded-lg span.feather-icon{
  background:#d15494 !important;
}
</style>

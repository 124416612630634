<template>
  <vs-card>
      <apexchart type="pie" height="350" :options="config.chartOptions" :series="config.series"></apexchart>
  </vs-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: 'ContentGraph4',
  props: ['data'],
  components: {
    apexchart: VueApexCharts,
  },
  created(){
    this.config.series = this.data.series
    this.config.chartOptions.labels = this.data.labels
  },
  data(){
    return {
      config: {
        "series": [

        ],
        "chartOptions": {
          "chart": {
            "type": "pie"
          },
          "labels": [

          ],
          "responsive": [
            {
              "breakpoint": 480,
              "options": {
                "chart": {
                  "width": 200
                },
                "legend": {
                  "position": "bottom"
                }
              }
            }
          ]
        }
      }
    }
  }
}
</script>

<template>
    <div>
      <!-- <textarea ref="placeholder">{{JSON.stringify(config.series)}}</textarea> -->
      <!-- <button @click="json">update</button> -->
      <apexchart type="bubble" height="350" :options="config.chartOptions" :series="config.series"></apexchart>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: 'EmployeeGraph4',
  props: ['series'],
  components: {
    apexchart: VueApexCharts,
  },
  methods:{
    // json(){
    //   let obj = JSON.parse(this.$refs.placeholder.value)
    //   this.config.series =  obj
    // }
  },
  created(){
    this.config.series = this.series
  },
  data(){
    return {
      config: {
        "series": [
          {
            "name": "Event",
            "data": []
          },
          {
            "name": "Café Tables",
            "data": []
          }
        ],
        "chartOptions": {
          "chart": {
            "height": 350,
            "type": "bubble"
          },
          "dataLabels": {
            "enabled": false
          },
          "fill": {

          },
          "title": {
            "text": "Activities"
          },
          "xaxis": {
            "tickAmount": 2,
            "type": "datetime",
            "labels": {
              "rotate": 0
            }
          },
          "theme": {
            "palette": "palette2"
          }
        }
      }
    }
  }
}
</script>
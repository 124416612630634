<template>
  <div>

    <div>

      <div class="w-full bg-blue-50 p-0">

        <div class="flex justify-between">

          <!--   1:done   -->
          <!-- <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
            <vx-tooltip color="primary" text="How many employees were imported in the system" position="top">
              <statistics-card-line icon="UsersIcon" :statistic="data.total_count" statisticTitle="All Employees" type="area"></statistics-card-line>
            </vx-tooltip>
          </div> -->

          <div class="flex flex-col bg-white rounded-lg  w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3 regmember">
            <vx-tooltip color="primary" text="Members who downloaded and registered themselves on MHC app" position="top">
              <statistics-card-line icon="UserIcon" :statistic="data.registered_count" statisticTitle="Registered Members" type="area"></statistics-card-line>
            </vx-tooltip>
          </div>


          <div class="flex flex-col bg-white rounded-lg  w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3 events">
            <vx-tooltip color="primary" text="What percentage of Members engaged with content (Video/Music) on the platform" position="top">
              <statistics-card-line icon="UserCheckIcon" :statistic="data.active_percent" statisticTitle="Active Users (%)" type="area"></statistics-card-line>
            </vx-tooltip>
          </div>

          <div class="flex flex-col bg-white rounded-lg  w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3 cafe" >
            <vx-tooltip color="primary" text="Total Number of Times Members Viewed Videos" position="top">
              <statistics-card-line icon="VideoIcon" :statistic="data.video_interaction_count" statisticTitle="Total Video Interactions" type="area"></statistics-card-line>
            </vx-tooltip>
          </div>
     
          <div class="flex flex-col bg-white rounded-lg  w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3 Totlamedia">
            <vx-tooltip color="primary" text="Percentage of Members who joined conversations hosted in the café" position="top">
              <statistics-card-line icon="CoffeeIcon" :statistic="data.cafe_participation_percent" statisticTitle="Café Interactions (%)" type="area"></statistics-card-line>
            </vx-tooltip>
          </div>


        </div>

      </div>

    </div>


    <div>

      <div class="w-full bg-blue-50 p-0">

        <div class="flex justify-between">

          <div class="flex flex-col bg-white rounded-lg  w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3 Activeuser">
            <vx-tooltip color="primary" text="Percentage of registered users who joined events hosted" position="top">
              <statistics-card-line icon="ActivityIcon" :statistic="data.event_engagement_percent" statisticTitle="Event Engagement %" type="area"></statistics-card-line>
            </vx-tooltip>
          </div>
    

          <div class="flex flex-col bg-white rounded-lg  w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3 Totalvideo">
            <vx-tooltip color="primary" text="Total number of media Viewed" position="top">
              <statistics-card-line icon="VideoIcon" :statistic="data.media_view_count" statisticTitle="Total Media Content" type="area"></statistics-card-line>
            </vx-tooltip>
          </div>


          <!-- <div class="flex flex-col bg-white rounded-lg  w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3">
            <vx-tooltip color="primary" text="Total Offers Available on the Platform" position="top">
              <statistics-card-line icon="GiftIcon" :statistic="data.offers_available_count" statisticTitle="Total Offers" type="area"></statistics-card-line>
            </vx-tooltip>
          </div>

          <div class="flex flex-col bg-white rounded-lg  w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3" style="visibility: hidden;">
            <vx-tooltip color="primary" text="Total Offers Available on the Platform" position="top">
              <statistics-card-line icon="GiftIcon" :statistic="35" statisticTitle="Total Offers" type="area"></statistics-card-line>
            </vx-tooltip>
          </div> -->

        </div>

      </div>
    
    </div>

  </div>
</template>

<script>
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";

export default {
  name: 'EmployeeCards',
  props: [
      'data'
  ],
  data(){
    return {

    }
  },
  components: {
    StatisticsCardLine,
  },
}
</script>

<style>
.regmember{
box-shadow: #fba73d 0px 1px 2px 0px, #fba73d 0px 1px 3px 1px, #fba73d 0px -1px 2px 0px, #fba73d 0px -1px 3px 1px;
}
.regmember.rounded-lg span.feather-icon{
  background:#fba73d !important
}
.regmember.rounded-lg span svg,.Activeuser.rounded-lg span svg,.Totalvideo.rounded-lg span svg,.cafe.rounded-lg span svg,.events.rounded-lg span svg,.Totlamedia.rounded-lg span svg{
  color:#fff
}

.Activeuser{
box-shadow:#4fb6ea 0px 1px 2px 0px, #4fb6ea 0px 1px 3px 1px, #4fb6ea 0px -1px 2px 0px, #4fb6ea 0px -1px 3px 1px;
}
.Activeuser.rounded-lg span.feather-icon{
  background:#4fb6ea !important;
}


.Totalvideo{
box-shadow:#685aba 0px 1px 2px 0px, #685aba 0px 1px 3px 1px, #685aba 0px -1px 2px 0px, #685aba 0px -1px 3px 1px;
}
.Totalvideo.rounded-lg span.feather-icon{
  background:#685aba !important;
}

.cafe{
box-shadow:#56eb9f 0px 1px 2px 0px,#56eb9f 0px 1px 3px 1px, #56eb9f 0px -1px 2px 0px, #56eb9f 0px -1px 3px 1px;
}
.cafe.rounded-lg span.feather-icon{
  background:#56eb9f !important;
}


.events{
box-shadow:#f2513d 0px 1px 2px 0px,#f2513d 0px 1px 3px 1px,#f2513d 0px -1px 2px 0px, #f2513d 0px -1px 3px 1px;
}
.events.rounded-lg span.feather-icon{
  background:#f2513d !important;
}


.Totlamedia{
box-shadow:#d15494 0px 1px 2px 0px,#d15494 0px 1px 3px 1px,#d15494 0px -1px 2px 0px, #d15494 0px -1px 3px 1px;
}
.Totlamedia.rounded-lg span.feather-icon{
  background:#d15494 !important;
}
</style>
<template>
  <div>
    <vs-card>
      <apexchart type="bar" :height="getHeight" :options="compOpts" :series="config.series"></apexchart>


      <!--      <vs-row>
              <vs-col vs-w="12">
                <div style="width:100%; height:550px;">
                  <v-chart  :option="compOpts2"/>
                </div>
              </vs-col>
            </vs-row>-->

    </vs-card>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

//ECHARTS
//core
import {use} from "echarts/core";

//render
import {CanvasRenderer} from "echarts/renderers";

//chart
import {BarChart, LineChart} from "echarts/charts";

//component
import {
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from "echarts/components";

import VChart, {THEME_KEY} from "vue-echarts";

//install
use([
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  CanvasRenderer
]);

//
const labelOption = {
  show: true,
  position: 'insideBottom',
  distance: 15,
  align: 'left',
  verticalAlign: 'middle',
  rotate: 90,
  formatter: '{c}  {name|{a}}',
  fontSize: 16,
  rich: {
    name: {}
  }
};

export default {
  name: 'ContentGraph1',
  props: ['data'],
  components: {
    apexchart: VueApexCharts,
    VChart
  },
  created(){
    this.config.series = [
      {
        "name": "Watched For (Mins)",
        type: 'bar',
        barWidth: '60%',
        "data": this.data.data
      }
    ]

    this.config.chartOptions.xaxis.categories = this.data.categories
  },
  data() {
    return {
      config2:{
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },

        xAxis: [
          {
            type: 'category',
            // axisTick: { show: false },
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          }
        ],

        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: 'Direct',
            type: 'bar',
            barWidth: '60%',
            data: [10, 52, 200, 334, 390, 330, 220]
          }
        ]
      },


      //2ND GRAPH
      config: {
        "series": [
          {
            "data": [
              100,
            ]
          }
        ],
        "chartOptions": {
          "chart": {
            "type": "bar",
            "height": 350
          },
          "plotOptions": {
            "bar": {
              "borderRadius": 4,
              "horizontal": true,
              columnWidth: '30%',
            }
          },
          "dataLabels": {
            "enabled": false
          },
          "xaxis": {
            "categories": [

            ]
          },
          "title": {
            "text": ""
          }
        }
      }
    }
  },
  computed:{
    getHeight(){
      let h = this.config.chartOptions.xaxis.categories.length*20
      if(h<150){
        h = 150
      }

      return h
    },
    compOpts(){

      this.config.chartOptions.xaxis.categories = this.config.chartOptions.xaxis.categories.map((e)=>{
        return e
      })
      return this.config.chartOptions
    },
    compOpts2(){

      let config2 = {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },

            xAxis: [
              {
                type: 'category',
                // axisTick: { show: false },
                data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
              }
            ],

            yAxis: [
              {
                type: 'value'
              }
            ],
            series: [
              {
                name: 'Direct',
                type: 'bar',

                data: [10, 52, 200, 334, 390, 330, 220]
              }
            ]
          }

      config2.xAxis = this.config.chartOptions.xaxis
      config2.series = this.config.series
      return config2
    }
  }
}
</script>
<template>
  <div>

    <div>

      <!-- 1st row -->
      <div class="w-full bg-blue-50 p-0">

        <div class="flex justify-between">

          <div class="flex flex-col bg-white rounded-lg  w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3 regmember">
            <vx-tooltip color="primary" text="Total number of Café Tables held" position="top">
              <statistics-card-line icon="UsersIcon" :statistic="data.total_cafe_tables" statisticTitle="Café Tables" type="area"></statistics-card-line>
            </vx-tooltip>
          </div>


          <div class="flex flex-col bg-white rounded-lg  w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3 events">
            <vx-tooltip color="primary" text="Participation in Café Tables on Average" position="top">
            <statistics-card-line icon="CheckCircleIcon" :statistic="data.cafe_participation_pct ||0" statisticTitle="Café Table Participation (%)" type="area"></statistics-card-line>
            </vx-tooltip>
          </div>

          <div class="flex flex-col bg-white rounded-lg  w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3 cafe">
            <vx-tooltip color="primary" text="Average Café Length in Minutes" position="top">
            <statistics-card-line icon="CheckCircleIcon" :statistic="data.average_cafe_length +'hrs.' ||0" statisticTitle="Average Café Length" type="area"></statistics-card-line>
            </vx-tooltip>
          </div>
     
          <div class="flex flex-col bg-white rounded-lg  w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3 Totlamedia">
            <vx-tooltip color="primary" text="Café Rating on Average" position="top">
              <statistics-card-line icon="StarIcon" :statistic="data.average_cafe_rating_pct || '-'" statisticTitle="Average Café Rating (%)" type="area"></statistics-card-line>
            </vx-tooltip>
          </div>


        </div>

      </div>

      <!-- 2nd row -->
      <div class="w-full bg-blue-50 p-0">

        <div class="flex justify-between">

          <!-- <div class="flex flex-col bg-white rounded-lg  w-full m-2 overflow-hidden Totalvideo">
            <vx-tooltip color="primary" text="Total clicks on Confidant contact" position="top">
            <statistics-card-line icon="BoxIcon" :statistic="data.total_confidant_clicks" statisticTitle="Mentorship Clicks" type="area"></statistics-card-line>
            </vx-tooltip>
          </div> -->


          <div class="flex flex-col bg-white rounded-lg  w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3 Activeuser">
            <vx-tooltip color="primary" text="Total Confidant Chat based Tickets" position="top">
            <statistics-card-line icon="CoffeeIcon" :statistic="data.total_confidant_tickets_chat" statisticTitle="Confidant Chats" type="area"></statistics-card-line>
            </vx-tooltip>
          </div>

           <div class="flex flex-col bg-white rounded-lg  w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3 Totalvideo">
            <vx-tooltip color="primary" text="Total Confidant Call based Tickets" position="top">
            <statistics-card-line icon="CalendarIcon" :statistic="data.total_confidant_tickets_call" statisticTitle="Confidant Calls" type="area"></statistics-card-line>
            </vx-tooltip>
          </div>
     
          <!-- <div class="flex flex-col bg-white rounded-lg shadow-md w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3">
            <vx-tooltip color="primary" text="How May Users reached to Confidants using Offline Mode" position="top">
            <statistics-card-line icon="MessageCircleIcon" :statistic="data.total_confidant_offline_reach" statisticTitle="Confidant Offline Reach" type="area"></statistics-card-line>
            </vx-tooltip>
          </div> --> 



        </div>

      </div>

    </div>


  </div>
</template>

<script>
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";

export default {
  name: 'OtherCards',
  props: [
      'data'
  ],
  data(){
    return {

    }
  },
  components: {
    StatisticsCardLine,
  },
}
</script>


<style>


.regmember{
box-shadow: #fba73d 0px 1px 2px 0px, #fba73d 0px 1px 3px 1px, #fba73d 0px -1px 2px 0px, #fba73d 0px -1px 3px 1px;
}
.regmember.rounded-lg span.feather-icon{
  background:#fba73d !important
}
.regmember.rounded-lg span svg,.Activeuser.rounded-lg span svg,.Totalvideo.rounded-lg span svg,.cafe.rounded-lg span svg,.events.rounded-lg span svg,.Totlamedia.rounded-lg span svg{
  color:#fff
}

.Activeuser{
box-shadow:#4fb6ea 0px 1px 2px 0px, #4fb6ea 0px 1px 3px 1px, #4fb6ea 0px -1px 2px 0px, #4fb6ea 0px -1px 3px 1px;
}
.Activeuser.rounded-lg span.feather-icon{
  background:#4fb6ea !important;
}


.Totalvideo{
box-shadow:#685aba 0px 1px 2px 0px, #685aba 0px 1px 3px 1px, #685aba 0px -1px 2px 0px, #685aba 0px -1px 3px 1px;
}
.Totalvideo.rounded-lg span.feather-icon{
  background:#685aba !important;
}

.cafe{
box-shadow:#56eb9f 0px 1px 2px 0px,#56eb9f 0px 1px 3px 1px, #56eb9f 0px -1px 2px 0px, #56eb9f 0px -1px 3px 1px;
}
.cafe.rounded-lg span.feather-icon{
  background:#56eb9f !important;
}


.events{
box-shadow:#f2513d 0px 1px 2px 0px,#f2513d 0px 1px 3px 1px,#f2513d 0px -1px 2px 0px, #f2513d 0px -1px 3px 1px;
}
.events.rounded-lg span.feather-icon{
  background:#f2513d !important;
}


.Totlamedia{
box-shadow:#d15494 0px 1px 2px 0px,#d15494 0px 1px 3px 1px,#d15494 0px -1px 2px 0px, #d15494 0px -1px 3px 1px;
}
.Totlamedia.rounded-lg span.feather-icon{
  background:#d15494 !important;
}
</style>
<template>
  <vs-card>
    <apexchart type="bar" height="400" :options="config.chartOptions" :series="config.series"></apexchart>
  </vs-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: 'ContentGraph1',
  props: ['data'],
  components: {
    apexchart: VueApexCharts,
  },
  created(){
    this.config.series = this.data.series
    this.config.chartOptions.xaxis.categories = this.data.categories
  },
  data(){
    return {
      config: {
        "series": [
          {
            "name": "Views",
            "data": [

            ]
          },
          {
            "name": "Likes",
            "data": [

            ]
          },
          {
            "name": "Favorites",
            "data": [

            ]
          }
        ],
        "chartOptions": {
          "chart": {
            "type": "bar",
            "height": 350
          },
          "plotOptions": {
            "bar": {
              "horizontal": false,
              "columnWidth": "55%",
              "endingShape": "rounded"
            }
          },
          "dataLabels": {
            "enabled": false
          },
          "stroke": {
            "show": true,
            "width": 2,
            "colors": [
              "transparent"
            ]
          },
          "xaxis": {
            "categories": [

            ]
          },
          "yaxis": {
            "title": {
              "text": "Count"
            }
          },
          "fill": {
            "opacity": 1
          }
        }
      }
    }
  }
}
</script>
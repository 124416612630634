<template lang="html">
  <div>
    <vs-table :data="list">
      <template slot="header">
      </template>
      <template slot="thead">
        <vs-th>
          Name
        </vs-th>
        <vs-th>
          Date
        </vs-th>
        <vs-th>
          #Users
        </vs-th>
        <vs-th>
          Event length (Mins.)
        </vs-th>
        <vs-th>
          Rating
        </vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data" :class="{pending: moment(data[indextr].event_time).unix()>moment().unix()}">
          <vs-td :data="data[indextr].title">
            {{data[indextr].title}}
          </vs-td>

          <vs-td :data="data[indextr].event_time">
            {{ moment(data[indextr].event_time).format('DD/MM/YYYY') }}
          </vs-td>

          <vs-td :data="data[indextr].no_users">
            {{moment(data[indextr].event_time).unix()>moment().unix()?'N/A':data[indextr].attendance}}
          </vs-td>

          <vs-td :data="data[indextr].favorites">
            {{data[indextr].event_length}}
          </vs-td>

          <vs-td :data="data[indextr].favorites">
            <span v-if="moment(data[indextr].event_time).unix()>moment().unix()">
              N/A
            </span>
            <span v-else>
              {{ data[indextr].avg_rating?data[indextr].avg_rating:'-' }}
            </span>
          </vs-td>

        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import moment from 'moment-timezone' 
import Vue from 'vue'

Vue.prototype.moment = moment

export default {
  name: 'EventGraph2',
  props:['list'],
  data(){
    return {

    }
  }
}
</script>

<style scoped>
.pending {
  background: #fffae0 !important;
}
</style>
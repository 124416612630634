<template lang="html">
  <div>
    <vs-table :data="list">
      <template slot="header">

      </template>
      <template slot="thead">
        <vs-th>
          Name
        </vs-th>
        <!--        <vs-th>-->
        <!--          Location-->
        <!--        </vs-th>-->
        <vs-th>
          Clicks
        </vs-th>
        <vs-th>
          Website Visit
        </vs-th>
        <vs-th>
          Transactions
        </vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
          <vs-td :data="data[indextr].title">
            {{data[indextr].title}}
          </vs-td>

          <!--          <vs-td :data="data[indextr].location">-->
          <!--            {{data[indextr].country_name}}-->
          <!--          </vs-td>-->


          <vs-td :data="data[indextr].views">
            {{data[indextr].hits}}
          </vs-td>

          <vs-td :data="data[indextr].views">
            {{data[indextr].view_details}}
          </vs-td>

          <vs-td >
            -
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
export default {
  name: 'EmployeeGraph5',
  props:['list'],
 
}
</script>

<template>
  <div>

    <div class="w-full bg-blue-50 p-0">

      <div class="flex justify-between">

        <div class="flex flex-col bg-white rounded-lg  w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3 regmember">
          <vx-tooltip color="primary" text="Total Number of Media on the platform" position="top">
            <statistics-card-line icon="SendIcon" :statistic="data.total_available_media" statisticTitle="Media" type="area"></statistics-card-line>
          </vx-tooltip>
        </div>


        <div class="flex flex-col bg-white rounded-lg  w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3 events">
          <vx-tooltip color="primary" text="Total Number of Video on the platform" position="top">
            <statistics-card-line icon="VideoIcon" :statistic="data.total_available_video" statisticTitle="Videos" type="area"></statistics-card-line>
          </vx-tooltip>
        </div>

        <!-- <div class="flex flex-col bg-white rounded-lg  w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3 ">
          <vx-tooltip color="primary" text="Total Number of Music on the platform" position="top">
            <statistics-card-line icon="MusicIcon" :statistic="data.total_available_music" statisticTitle="Music" type="area"></statistics-card-line>
          </vx-tooltip>
        </div> -->
   
        <div class="flex flex-col bg-white rounded-lg  w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3 cafe">
          <vx-tooltip color="primary" text="Total Number of Media available" position="top">
            <statistics-card-line icon="ClockIcon" :statistic="data.total_media_available_length+'hrs.'" statisticTitle="Total Available Media" type="area"></statistics-card-line>
          </vx-tooltip>
        </div>

      </div>

    </div>

    <div class="w-full bg-blue-50 p-0">

      <div class="flex justify-between">

        <!-- <div class="flex flex-col bg-white rounded-lg shadow-md w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3">
          <vx-tooltip color="primary" text="Our Story Count" position="top">
            <statistics-card-line icon="MessageCircleIcon" :statistic="data.total_content_our_story" statisticTitle="Our Story" type="area"></statistics-card-line>
          </vx-tooltip>
        </div> -->


        <div class="flex flex-col bg-white rounded-lg shadow-md w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3" style="visibility: hidden;">

        </div>

        <div class="flex flex-col bg-white rounded-lg shadow-md w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3" style="visibility: hidden;">

        </div>

        <div class="flex flex-col bg-white rounded-lg shadow-md w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3" style="visibility: hidden;">

        </div>

      </div>

    </div>

  </div>
</template>

<script>
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";

export default {
  name: 'ContentCards',
  props: [
      'data'
  ],
  data(){
    return {

    }
  },
  components: {
    StatisticsCardLine,
  },
}
</script>

<style>


.regmember{
box-shadow: #fba73d 0px 1px 2px 0px, #fba73d 0px 1px 3px 1px, #fba73d 0px -1px 2px 0px, #fba73d 0px -1px 3px 1px;
}
.regmember.rounded-lg span.feather-icon{
  background:#fba73d !important
}
.regmember.rounded-lg span svg,.Activeuser.rounded-lg span svg,.Totalvideo.rounded-lg span svg,.cafe.rounded-lg span svg,.events.rounded-lg span svg,.Totlamedia.rounded-lg span svg{
  color:#fff
}

.Activeuser{
box-shadow:#4fb6ea 0px 1px 2px 0px, #4fb6ea 0px 1px 3px 1px, #4fb6ea 0px -1px 2px 0px, #4fb6ea 0px -1px 3px 1px;
}
.Activeuser.rounded-lg span.feather-icon{
  background:#4fb6ea !important;
}


.Totalvideo{
box-shadow:#685aba 0px 1px 2px 0px, #685aba 0px 1px 3px 1px, #685aba 0px -1px 2px 0px, #685aba 0px -1px 3px 1px;
}
.Totalvideo.rounded-lg span.feather-icon{
  background:#685aba !important;
}

.cafe{
box-shadow:#56eb9f 0px 1px 2px 0px,#56eb9f 0px 1px 3px 1px, #56eb9f 0px -1px 2px 0px, #56eb9f 0px -1px 3px 1px;
}
.cafe.rounded-lg span.feather-icon{
  background:#56eb9f !important;
}


.events{
box-shadow:#f2513d 0px 1px 2px 0px,#f2513d 0px 1px 3px 1px,#f2513d 0px -1px 2px 0px, #f2513d 0px -1px 3px 1px;
}
.events.rounded-lg span.feather-icon{
  background:#f2513d !important;
}


.Totlamedia{
box-shadow:#d15494 0px 1px 2px 0px,#d15494 0px 1px 3px 1px,#d15494 0px -1px 2px 0px, #d15494 0px -1px 3px 1px;
}
.Totlamedia.rounded-lg span.feather-icon{
  background:#d15494 !important;
}
</style>
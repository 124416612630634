<template>
  <div>
    <vs-card>
      <apexchart type="pie" height="350" :options="config.chartOptions" :series="graph.data"></apexchart>
    </vs-card>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: 'EmployeeGraph2',
  props: ['graph'],
  components: {
    apexchart: VueApexCharts,
  },
  created(){
    this.config.chartOptions.labels = this.graph.labels
  },
  data () {
    return {
      config: {

        "series": [
          44,
          55,
          13,
          43
        ],
        "chartOptions": {
          noData: {
            text: 'No Data Found',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: 'grey',
              fontSize: '14px',
              // fontFamily: undefined
            }
          },
          tooltip: {
            custom: function({series, seriesIndex, dataPointIndex, w}) {
              // console.log({
              //   series, seriesIndex, dataPointIndex, w
              // })
              // var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
              return series[seriesIndex]+'%'
            }
          },
          "chart": {
            "width": "100%",
            "type": "pie"
          },
          "labels": [

          ],
          "dataLabels": {
            "enabled": true,
            formatter: function(value, { seriesIndex, dataPointIndex, w }) {
              return Number(value).toFixed() + '%' //w.config.series[seriesIndex].name + ":  " + value
            }
          },
          "stroke": {
            "curve": "straight"
          },
          "title": {
            "text": "",
            "align": "left"
          }
        }
      }
    }
  }
}
</script>
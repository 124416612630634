<template>
  <div>
    <vs-card>
      <!-- <textarea>{{JSON.stringify(series)}}</textarea> -->
      <apexchart type="line" height="350" :options="config.chartOptions" :series="series"></apexchart>
    </vs-card>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: 'OtherGraph2',
  props: ['series'],
  components: {
    apexchart: VueApexCharts,
  },
  created(){
    // this.config.chartOptions.labels = this.graph.labels
  },
  data () {
    return {
      config: {
        series: [
     
        ],
        chartOptions: {
          chart: {
            height: 350,
            type: 'line',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            toolbar: {
              show: false
            }
          },
          colors: ['#D82148', '#2666CF'],
          dataLabels: {
            enabled: true,
          },
          stroke: {
            curve: 'smooth'
          },
          title: {
            text: '',
            align: 'left'
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          markers: {
            size: 1
          },
          xaxis: {
            categories: [],
            title: {
              text: 'Month'
            },
            "type": "category",
          },
          yaxis: {
            title: {
              text: 'Tickets'
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
          }
        },
      }
    }
  }
}
</script>
<template>
  <div>


    <div v-if="$store.state.dashboard.is_loaded">

      <vs-row>
        <!-- interval OSD -->
        <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="6" >
          <div class="title_range">
            Showing data for the interval between <b>{{formatReadable(date_from, 'll')}}</b> and <b>{{formatReadable(date_to, 'll')}}</b>
          </div>
        </vs-col>

        <!-- datepicker : icon-->
        <vs-col vs-type="flex" vs-justify="right" vs-align="center" vs-w="6" >
          <vs-button @click="is_datepik_visible=true" color="success">
            <vs-icon icon="event"></vs-icon>
          </vs-button>
        </vs-col>
      </vs-row>

      <br>

      <div id="pick_up_datetime">
        <!-- datepicker -->
        <Fx-Modal v-show="is_datepik_visible" @close="closeDatePik">
          <template v-slot:header></template>
          <template v-slot:body>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
              <DateRangePicker :panel="'week'" :panels="['week', 'month', 'quarter', 'year']" @update="datepikUpdate" :future="false"></DateRangePicker>
            </vs-col>
          </template>
          <template v-slot:footer>
          </template>
        </Fx-Modal>
      </div>


      <!--This is for tab headings-->
      <vs-row class="tab_heading_container">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3" class="tab_heading_item_parent"
                :class="{tab_heading_active: visible_tab==='employee'}">
          <span @click="tabclick('employee')" class="tab_heading_item">Members</span>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3" class="tab_heading_item_parent"
                :class="{tab_heading_active: visible_tab==='event'}">
          <span @click="tabclick('event')" class="tab_heading_item">Events</span>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3"
                class="tab_heading_item_parent" :class="{tab_heading_active: visible_tab==='other'}">
          <span @click="tabclick('other')" class="tab_heading_item">Café and Confidant </span>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3" class="tab_heading_item_parent"
                :class="{tab_heading_active: visible_tab==='content'}">
          <span @click="tabclick('content')" class="tab_heading_item">Content</span>
        </vs-col>

      </vs-row>


      <!-- [[[[[[[[[[[[[[[[[[[[[[[[== EMPLOYEE REPORT ==]]]]]]]]]]]]]]]]]]]]]]]]]]] -->
      <div v-show="visible_tab==='employee'" class="emp-sec">

        <vs-row>
          <vs-col vs-w="12">
            <EmployeeCards :data="$store.state.dashboard.dashboard.employee.cards"></EmployeeCards>
          </vs-col>
        </vs-row>

       <br>
        <!-- <vs-row>
          <vs-col vs-w="12">
            <h5 class="chart_sec_heading">Employee Interests</h5>
            -->
            <!-- sunburst chart -->
            <!-- <div style="height: 480px;" v-if="is_chart1_has_data">
              <v-chart  :option="chart1"  />
            </div>
            <div v-else>
              <h4 style="margin: 100px;    text-align: center;">No data</h4>
            </div> -->
            <!-- <EmployeeGraph6 :series="$store.state.dashboard.dashboard.employee.graph6"></EmployeeGraph6>-->
          <!-- </vs-col>
        </vs-row> -->

        <vs-row>
        <!--          <vs-col vs-w="6">
                    <h5 class="chart_sec_heading">Popular Media Categories</h5>
                    <EmployeeGraph2 :graph="$store.state.dashboard.dashboard.employee.graph2"></EmployeeGraph2>
                  </vs-col>-->
          <vs-col vs-w="12">
            <h5 class="chart_sec_heading">DAU</h5>
            <EmployeeGraph3 :series="$store.state.dashboard.dashboard.employee.graph3"></EmployeeGraph3>
          </vs-col>
        </vs-row>

        <br>

        <h5 class="chart_sec_heading">Members Trends</h5>
        <EmployeeGraph1 :series="$store.state.dashboard.dashboard.employee.graph1" width="100%"></EmployeeGraph1>

        <br>




        <!--      <vs-row>-->
        <!--        <vs-col vs-w="12">-->
        <!--          <EmployeeGraph4 :series="$store.state.dashboard.dashboard.employee.graph4"></EmployeeGraph4>-->
        <!--        </vs-col>-->
        <!--      </vs-row>-->

        <br>

        <!-- <vs-col vs-w="12">
          <h5 class="chart_sec_heading">Popular Media Categories</h5>
          <div style="height: 480px;"  v-if="is_chart2_has_data">
            <v-chart  :option="chart2" />
          </div>
          <div v-else>
            <h4 style="margin: 100px;    text-align: center;">No data</h4>
          </div> -->
          <!-- <EmployeeGraph7 :series="$store.state.dashboard.dashboard.employee.graph7"></EmployeeGraph7>-->
        <!-- </vs-col> -->

        <!-- <vs-row>
          <vs-col vs-w="12">
            <h5 class="chart_sec_heading">Offers</h5>
            <EmployeeGraph5 :list="$store.state.dashboard.dashboard.employee.graph8"></EmployeeGraph5>
          </vs-col>
        </vs-row> -->

      </div>


      <!-- == EVENT == -->
      <div v-show="visible_tab==='event'" class="evt-sec">

        <vs-row>
          <vs-col vs-w="12">
            <EventCards :data="$store.state.dashboard.dashboard.event.cards"></EventCards>
          </vs-col>
        </vs-row>

        <br>

       <!--      <vs-row>
              <vs-col vs-w="12">
                <EventGraph1 :graph="$store.state.dashboard.dashboard.event.graph1"></EventGraph1>
              </vs-col>
            </vs-row>
       -->
        <vs-row>
          <vs-col vs-w="12">
            <h5 class="chart_sec_heading">Event List</h5>
            <EventGraph2 :list="$store.state.dashboard.dashboard.event.graph2"></EventGraph2>
          </vs-col>
        </vs-row>

      </div>


      <!-- == OTHER == -->
      <div v-show="visible_tab==='other'" class="other-sec">
        <OtherCards :data="$store.state.dashboard.dashboard.other.cards"></OtherCards>

        <br>

        <vs-row>
          <vs-col vs-w="12">
            <h5 class="chart_sec_heading">Café Rooms</h5>
            <CafeGraph1 :series="$store.state.dashboard.dashboard.other.graph1" width="100%"></CafeGraph1>
          </vs-col>
        </vs-row>

        <br>



        <vs-row>
          <vs-col vs-w="12">
            <h5 class="chart_sec_heading">Confidant Tickets</h5>
            <CafeGraph2 :series="$store.state.dashboard.dashboard.other.graph2" width="100%"></CafeGraph2>
          </vs-col>
        </vs-row>
      </div>


      <!-- == CONTENT == -->
      <div v-show="visible_tab==='content'" class="cont-sec">
        <vs-row>
          <vs-col vs-w="12">
            <ContentCards :data="$store.state.dashboard.dashboard.content.cards"></ContentCards>
          </vs-col>
        </vs-row>

        <br>

        <!--        <vs-row>
                  <vs-col vs-w="12">
                    <h5 class="chart_sec_heading">Most Engaging Videos</h5>
                    <ContentGraph1 :data="$store.state.dashboard.dashboard.content.graph1"></ContentGraph1>
                  </vs-col>
                </vs-row>-->


        <br>

        <!-- <vs-row>
          <vs-col vs-w="12">
            <h5 class="chart_sec_heading">Views by Category</h5>
            <ContentGraph2 :data="$store.state.dashboard.dashboard.content.graph2" width="50%"></ContentGraph2>
          </vs-col>
        </vs-row> -->

       

        <!-- <vs-row>
          <vs-col vs-w="6">
            <h5 class="chart_sec_heading">Popular Programs</h5>
            <ContentGraph3 :data="$store.state.dashboard.dashboard.content.graph3" width="50%"></ContentGraph3>
          </vs-col>
          <vs-col vs-w="6" style="text-align: right">
            <h5 class="chart_sec_heading" style="text-align: start;">Popular tags</h5>
            <ContentGraph4 :data="$store.state.dashboard.dashboard.content.graph4" width="50%" style="display: inline-block;padding-bottom: 14px;"></ContentGraph4>
          </vs-col>
        </vs-row> -->

        <br>


        <vs-row>
          <vs-col vs-w="12">
            <h5 class="chart_sec_heading">Most Watched Videos</h5>
            <ContentGraph5 :data="$store.state.dashboard.dashboard.content.graph5"></ContentGraph5>
          </vs-col>
        </vs-row>

        <br>

        <!-- <vs-row>
          <vs-col vs-w="12">
            <h5 class="chart_sec_heading">Most listened Music</h5>
            <ContentGraph6 :data="$store.state.dashboard.dashboard.content.graph6"></ContentGraph6>
          </vs-col>
        </vs-row> -->

        <br>

        <!--      <vs-row>-->
        <!--        <vs-col vs-w="12">-->
        <!--          <ContentGraph7 :data="apiData.content.graph7"></ContentGraph7>-->
        <!--        </vs-col>-->
        <!--      </vs-row>-->

      </div>


    </div>

  </div>
</template>

<script>


import moment from 'moment-timezone';
import Vue from 'vue'
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";


//UI
import FxModal from './new-dash/blocks/Modal'

//Employee
import EmployeeCards from './new-dash/employee/Employee-cards.vue'
import EmployeeGraph1 from './new-dash/employee/graphs/1.vue'
import EmployeeGraph2 from './new-dash/employee/graphs/2.vue'
import EmployeeGraph3 from './new-dash/employee/graphs/3.vue'
import EmployeeGraph4 from './new-dash/employee/graphs/4.vue'
import EmployeeGraph5 from './new-dash/employee/graphs/5.vue'
import EmployeeGraph6 from './new-dash/employee/graphs/6.vue'
import EmployeeGraph7 from './new-dash/employee/graphs/7.vue'



//Content
import ContentCards from './new-dash/content/Content-cards.vue'
import ContentGraph1 from './new-dash/content/graphs/1.vue'
import ContentGraph2 from './new-dash/content/graphs/2.vue'
import ContentGraph3 from './new-dash/content/graphs/3.vue'
import ContentGraph4 from './new-dash/content/graphs/4.vue'
import ContentGraph5 from './new-dash/content/graphs/5.vue'
import ContentGraph6 from './new-dash/content/graphs/6.vue'
import ContentGraph7 from './new-dash/content/graphs/7.vue'


//Event
import EventCards from './new-dash/event/Event-cards.vue'
import EventGraph1 from './new-dash/event/graphs/1.vue'
import EventGraph2 from './new-dash/event/graphs/2.vue'


//Other
import OtherCards from './new-dash/other/Other-cards.vue'
import CafeGraph1 from './new-dash/other/graphs/1.vue'
import CafeGraph2 from './new-dash/other/graphs/2.vue'



import DateRangePicker from 'vue-mj-daterangepicker'
import 'vue-mj-daterangepicker/dist/vue-mj-daterangepicker.css'
// cust: https://vuejsexamples.com/vue-js-date-range-picker-with-multiples-ranges-and-presets/

Vue.use(DateRangePicker)


// ** dummy DATA **
import EmpGraph1DATA from './new-dash/data/emp_graph1_data.json'
import EmpGraph2DATA from './new-dash/data/emp_graph2_data.json'
import EmpGraph3DATA from './new-dash/data/emp_graph3_data.json'
import EmpGraph4DATA from './new-dash/data/emp_graph4_data.json'

// ** dummy DATA **
import ContentGraph1DATA from './new-dash/data/content_graph1_data.json'
import ContentGraph2DATA from './new-dash/data/content_graph2_data.json'
import ContentGraph3DATA from './new-dash/data/content_graph3_data.json'
import ContentGraph4DATA from './new-dash/data/content_graph4_data.json'
import ContentGraph5DATA from './new-dash/data/content_graph5_data.json'
import ContentGraph6DATA from './new-dash/data/content_graph6_data.json'
import ContentGraph7DATA from './new-dash/data/content_graph7_data.json'

// ** dummy DATA **
// import EventGraph1DATA from './new-dash/data/event_graph1_data.json'





use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  SunburstChart,
  GridComponent,
  LineChart,
  ToolboxComponent
]);

//component
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent
} from "echarts/components";

import VChart, { THEME_KEY } from "vue-echarts";

//core
import { use } from "echarts/core";

//render
import { CanvasRenderer } from "echarts/renderers";

//chart
import { PieChart, SunburstChart, LineChart } from "echarts/charts";

export default {
  data() {
    return {
      is_chart1_has_data: false,
      is_chart2_has_data: false,
      renderKey1: 0,
      renderKey2: 0,

      category_option: {
        toolbox: {
          show: false,
          feature: {
            dataView: {
              readOnly: false
            },
            restore: {},
            saveAsImage: {}
          }
        },
        title: {
          text: "",
          subtext: "",
          textStyle: {
            fontSize: 18,
            align: "center"
          },
          subtextStyle: {
            align: "center"
          },
          sublink: ""
        },
        series: {
          label: {
            formatter: "\n{b}\n\n{c}%"
          },
          emphasis: {
            focus: "ancestor"
          },
          downplay: {
            label: {
              formatter: "\n{b}\n\n{c}%"
            }
          },
          type: "sunburst",
          data: [

          ],
          radius: [
            0,
            "95%"
          ],
          levels: [
            {},
            {
              r0: "15%",
              r: "40%",
              itemStyle: {
                borderWidth: 2
              },
              label: {
                rotate: "tangential"
              }
            },
            {
              r0: "40%",
              r: "90%",
              label: {
                align: "right"
              }
            },

          ]

        }
      },
      is_loading: true,
      visible_tab: 'employee',
      is_datepik_visible: false,

      date_from: '',
      date_to: '',
      date_window: '',

      //api-data
      apiData: {
        employee: {
          //[tiles]
          cards: {
            total_count: 10,
            registered_count: 10,
            active_percent: 2,
            video_interaction_count: 3,
            cafe_participation_percent: 3,
            event_engagement_percent: 3,
            confidant_chat_count: 3,
            offers_view_more_count: 3,
          },
          //[graph]1
          graph1: EmpGraph1DATA,
          //[graph]2
          graph2: EmpGraph2DATA,
          //[graph]3
          graph3: EmpGraph3DATA,
          //[graph]4
          graph4: EmpGraph4DATA

          //[close -graph1]

        },

        content: {
          //[tiles]
          cards: {
            new_media_count: 10,  //  media
            video_count: 10,      //  video
            music_count: 2,       // music
            view_percentage: 3,    // hrs of media
            like_percentage: 3,     //
            dislike_percentage: 3,
            favorite_percentage: 3,
            watched_hours: 350+'hrs',
          },
          //[graph]1
          graph1: ContentGraph1DATA,
          //[graph]2
          graph2: ContentGraph2DATA,
          //[graph]3
          graph3: ContentGraph3DATA,
          //[graph]4
          graph4: ContentGraph4DATA,
          //[graph]5
          graph5: ContentGraph5DATA,
          //[graph]6
          graph6: ContentGraph6DATA,
          //[graph]7
          graph7: ContentGraph7DATA,
        },

        event: {
          //[tiles]
          cards: {
            total_events: 10,
            event_engagement_percent: 10,
            event_rating_percent: 2,
            event_average_length: 3,
            total_cafe_tables: 3,
            cafe_table_participation_rate: 3,
            cafe_table_average_stay: 3,
            cafe_table_average_chats: 6,
          },
          //[graph]1
          //graph1: EventGraph1DATA,
        },

        other: {},
      }
    };
  },
  components: {

    VChart,
    StatisticsCardLine,

    //UI
    FxModal,

    //Employee
    EmployeeCards,
    EmployeeGraph1,
    EmployeeGraph2,
    EmployeeGraph3,
    EmployeeGraph4,
    EmployeeGraph5,
    EmployeeGraph6,
    EmployeeGraph7,

    //Content
    ContentCards,
    ContentGraph1,
    ContentGraph2,
    ContentGraph3,
    ContentGraph4,
    ContentGraph5,
    ContentGraph5,
    ContentGraph6,
    ContentGraph7,

    //Event
    EventCards,
    EventGraph1,
    EventGraph2,

    //Other
    OtherCards,
    CafeGraph1,
    CafeGraph2
  },
  watch:{
    chart1(val){
      this.is_chart1_has_data = this.$store.state.dashboard.dashboard.employee.graph6.total_cat_sum != 0
    },
    chart2(val){
      this.is_chart2_has_data = this.$store.state.dashboard.dashboard.employee.graph7.total_cat_sum != 0
    }
  },
  computed: {
    dashboard() {
      return this.$store.getters.dashboard;
    },
    chart1(){

      if(!this.is_loading){

        let json_grph = JSON.parse( JSON.stringify(this.$store.state.dashboard.dashboard.employee.graph6.mod_map) )

        // for(let i=0; i<json_grph.length;i++){
        //   json_grph[i].value = parseInt(json_grph[i].value) || 0
        //
        //   for(let j=0; j<json_grph[i].children.length;j++){
        //     json_grph[i].children[j].value = parseInt(json_grph[i].children[j].value) || 0
        //   }
        // }

        let json = JSON.parse( JSON.stringify(this.category_option) )
        json.series.data = JSON.parse( JSON.stringify(json_grph) )

        return json

      }else{
        this.is_chart1_has_data = true
        return this.category_option
      }

    },
    chart2(){

      if(!this.is_loading){

        let json_grph = JSON.parse( JSON.stringify(this.$store.state.dashboard.dashboard.employee.graph7.mod_map) )

        let json = JSON.parse( JSON.stringify(this.category_option) )
        json.series.data = JSON.parse( JSON.stringify(json_grph) )

        return json

      }else{
        this.is_chart2_has_data = true
        return this.category_option
      }

    }
  },
  mounted() {

  },
  created() {
    /*
    this.date_from = moment().utc().startOf('week').format('YYYY-MM-DD HH:mm:ss')
    this.date_to = moment().utc().endOf('week').format('YYYY-MM-DD HH:mm:ss')

    this.date_window = 'week'
     */

    this.date_from = moment().utc().startOf('year').format('YYYY-MM-DD HH:mm:ss')
    this.date_to = moment().utc().endOf('year').format('YYYY-MM-DD HH:mm:ss')

    this.date_window = 'year'

    this.load()
  },
  methods: {
    calcChart1(res) {

      this.is_chart1_has_data = this.$store.state.dashboard.dashboard.employee.graph6.total_cat_sum != 0

      let json_grph = JSON.parse( JSON.stringify(this.$store.state.dashboard.dashboard.employee.graph6.mod_map) )


    },
    formatReadable(date,fmt){
      if(moment(date).unix() > moment().unix()){
        return 'Today'
      }
      return moment(date).format(fmt);
    },
    load(){

      // alert(this.date_from + ' | ' + this.date_to + ' | ' + this.date_window)
      this.is_loading = true
      this.$vs.loading();
      this.$store.dispatch('dashboard/initializeDashboard', 
      {
        org_id: this.$store.state.AppActiveUser.org_id,
        date_from: this.date_from,
        date_to: this.date_to,
        date_window: this.date_window,

      }).then((res)=>{





        // Vue.set(this.category_option.series, 'data', res.data.employee.graph6)
        // Vue.set(this.category_option.series, 'data', JSON.parse(json))


        this.is_loading = false

        this.renderKey1 = Math.random()
        this.renderKey2 = Math.random()
        this.$vs.loading.close();
      })
    },
    
    //handles the task of hiding and showing correct tab on click on tab heading
    tabclick(t) {
      this.visible_tab = t
    },

    datepikUpdate(arg) {
      console.log((arg))
      this.is_datepik_visible = false

      this.date_from    = moment(arg.from).utc().format('YYYY-MM-DD HH:mm:ss')
      this.date_to      = moment(arg.to).utc().format('YYYY-MM-DD HH:mm:ss')
      this.date_window  = arg.panel

      this.load()
    },

    closeDatePik() {
      this.is_datepik_visible = false
    }
  },

};
</script>
<style>
.tab_heading_item {
  font-weight: bold;
  width: 100%;
  text-align: center;

}

.tab_heading_item_parent {
  background: #fff;
font-weight:600;
  cursor: pointer;
}

.tab_heading_active{

  background:#00a0de;
  padding: 10px;
  color: white;
  display: inline-block;
  border-radius: 10px;
}

.tab_heading_container{
  margin-bottom: 20px;
  padding: 5px;
  background: #fff;
  border-radius:6px;
}

.title_range{
  color: silver;
}

.chart_sec_heading{
  padding:20px; background : #555; border-radius: 5px 5px 0px 0px;
  color:#fff;font-weight:600
}


#pick_up_datetime .modal-header { border-bottom: none; }
#pick_up_datetime .btn-close {
  padding: 5px;
  background: gray;
  width: 37px;
  color: white;
}
#pick_up_datetime .modal-body {
  padding: 18px 12px;
}
</style>

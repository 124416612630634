<template>
  <div>
    <vs-card>
      <div style="height:600px;">
        <v-chart  :option="config" />
      </div>
      <textarea name="" id="" cols="30" rows="10">{{JSON.stringify(series)}}</textarea>
    </vs-card>
  </div>

</template>

<script>
import VueApexCharts from "vue-apexcharts";

import moment from 'moment-timezone'
import Vue from 'vue'

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  SunburstChart,
  GridComponent,
  LineChart,
  ToolboxComponent
]);

//component
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent
} from "echarts/components";

import VChart, { THEME_KEY } from "vue-echarts";


//core
import { use } from "echarts/core";

//render
import { CanvasRenderer } from "echarts/renderers";

//chart
import { PieChart, SunburstChart, LineChart } from "echarts/charts";

let sdata = [
  {
    name: 'Get centered',
    itemStyle: {
      color: '#3465a4'
    },
    children: [
      {
        name: 'Self Care',
        value: 6,
        itemStyle: {
          color: '#376bae'
        }
      },
      {
        name: 'Music',
        itemStyle: {
          color: '#5185c8'
        },
        value: 4,
      }
    ]
  },

  {
    name: 'Social',
    itemStyle: {
      color: '#4e9a06'
    },
    children: [
      {
        name: 'isabel offers',
        value: 3,
        itemStyle: {
          color: '#89f622'
        }
      }
    ]
  },

  {
    name: 'Get Active',
    itemStyle: {
      color: '#f57900'
    },
    children: [
      {
        name: 'Workout',
        value: 10,
        itemStyle: {
          color: '#e67100'
        }
      },
      {
        name: 'Our Story',
        itemStyle: {
          color: '#ff8b1a'
        },
        value: 11,
      },
      {
        name: 'Nutrition',
        itemStyle: {
          color: '#ffa54d'
        },
        value: 9,
      }
    ]
  },


  {
    name: 'Financial',
    itemStyle: {
      color: '#5c3566'
    },
    children: [
      {
        name: 'Financial well-being',
        value: 5,
        itemStyle: {
          color: '#884e97'
        }
      }
    ]
  },
];


export default {
  name: 'EmployeeGraph6',
  props: ['series'],
  components: {
    apexchart: VueApexCharts, VChart
  },
  watch: {
    series(val) {

      // Vue.set(this.config.series[0], 'data', val.arrDauY)
      // Vue.set(this.config.chartOptions.xaxis, 'categories', val.arrDauX)
      // this.renderKey = Math.random()

    }
  },
  created() {
    // let val = this.series
    //
    //
    // this.config.series[0].data = val.arrDauY
    // this.config.chartOptions.xaxis.categories = val.arrDauX
    console.log('==============================================')
    console.log(this.series)
    this.config.series.data = this.series
  },
  data() {
    return {
      config: {
        toolbox: {
          show: true,
          feature: {
            dataView: { readOnly: false },
            restore: {},
            saveAsImage: {}
          }
        },
        title: {
          text: 'Employee Interests',
          subtext: '',
          textStyle: {
            fontSize: 18,
            align: 'center'
          },
          subtextStyle: {
            align: 'center'
          },
          sublink: 'https://worldcoffeeresearch.org/work/sensory-lexicon/'
        },
        series: {
          type: 'sunburst',
          data: [],  //<--- data
          radius: [0, '95%'],
          sort: undefined,
          emphasis: {
            focus: 'ancestor'
          },
          levels: [
            {},
            {
              r0: '15%',
              r: '35%',
              itemStyle: {
                borderWidth: 2
              },
              label: {
                rotate: 'tangential'
              }
            },
            {
              r0: '35%',
              r: '90%',
              label: {
                align: 'right'
              }
            },
            {
              r0: '70%',
              r: '72%',
              label: {
                position: 'outside',
                padding: 3,
                silent: false
              },
              itemStyle: {
                borderWidth: 3
              }
            }
          ]
        }
      },

    }
  },
  methods: {}
}
</script>